/* ------------------------------------*\
  #Imports
\*------------------------------------ */

@import '../../../../../assets/css/variables/variables';

/* ------------------------------------*\
  #Component styles
\*------------------------------------ */

.Category {
  color: $greyDarkest;
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  margin-top: 5px;
  position: relative;

  :global {

    .themed-loader {
      z-index: 2;
    }
  }
}

.Filters {
  grid-row: 1/7;
  width: 92%;
}

.CategoryProductList {
  display: grid;
  grid-template-columns: 24.5% 75.5%;
  width: 100%;
}

.CategoryHeaderImage {
  grid-column: 2;
  grid-row: 1;
}

.CategoryNameWrapper {
  display: flex;
  justify-content: left;
}

.CategoryName {
  display: inline;
  font-size: $fontSize-xl;
  font-weight: $font-weight-m;
  line-height: 26px;

  + span {
    color: $greyLighter;
    font-size: 20px;
    margin-top: 3px;
    padding-left: 5px;
  }
}

.CategoryHeader {
  margin-bottom: 5px;
  margin-top: 43px;
}

.CategoryProductListTop {
  align-items: center;
  border-bottom: 1px solid $greyMiddle;
  display: flex;
  grid-column: 2;
  grid-row: 2;
  justify-content: space-between;
  margin-bottom: 16px;
}

/* ------------------------------------*\
  #Component RWD
\*------------------------------------ */

@media screen and ( $tablet-media ) {

  .Category {
    display: block;
    margin-top: 15px;
  }

  .CategoryName {

    + span {
      margin-top: 2px;
    }
  }

  .CategoryHeader {
    margin-top: 0;
  }

  .CategoryProductList {
    grid-template-columns: 100%;
    width: 100%;
  }

  .CategoryHeaderImage {
    grid-column: 1;
    grid-row: 1;
  }

  .Filters {
    grid-row: 3;
    margin: 0px;
    width: 100%;
  }

  .CategoryProductListTop {
    border: none;
    display: grid;
    grid-column: 1;
    grid-row: 2;
    grid-template-columns: 100%;
    margin: 0;
  }
}
